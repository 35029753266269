import { createContext } from 'react'
import { IntlProvider } from 'react-intl'

const I18nContext = createContext()

const Provider = ({ element, props }) => {
  let { locale, defaultLocale, locales, originalPath, messages } =
    props.pageContext

  originalPath = originalPath.replace(/\[(\w+)\]/g, (match, p1) => props[p1])

  let context = {
    locale,
    defaultLocale,
    locales,
    originalPath,
    messages,
  }

  return (
    <I18nContext.Provider value={context}>
      <IntlProvider
        locale={locale}
        messages={messages}
        defaultLocale={defaultLocale}
      >
        {element}
      </IntlProvider>
    </I18nContext.Provider>
  )
}

export { I18nContext }

export default Provider
