import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { SSRProvider } from 'react-bootstrap'
import 'swp-components/lib/assets/styles/lib.css'

const cache = createCache({ key: 'x' })

export const wrapPageElement = ({ element, props }) => {
  return (
    <SSRProvider {...props}>
      <CacheProvider value={cache}>{element}</CacheProvider>
    </SSRProvider>
  )
}
